import { Component, OnInit, OnDestroy } from '@angular/core';
import { JsLoaderService } from 'src/app/shared/services/js-loader.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { LogService } from 'src/app/services/log/log.service';
import { CustomLocalStorage } from 'src/app/core/custom-storage/custom.local.storage';
import { Chart } from 'chart.js/auto';
// import Chart from 'chart.js/auto';
// declare let $ : any;
// declare let ApexCharts : any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  totalClients: any = 0;
  totalProducts: any = 0;
  totalOrders: any = 0;
  totalOrdersProgress: any = 0;
  totalCustomers: any = 0;
  custRegistToday: any = 0;

  dataProductFav: any;
  dataPending: any;

  date: Date = new Date();
  month: any = this.date.getMonth() + 1;
  dataOrderFailed: any = [];
  dataOrderSuccess: any = [];
  dataOrderFailedFinal: any = Array(this.month).fill(0);
  dataOrderSuccessFinal: any = Array(this.month).fill(0);

  chart: any;
  countSuccess: any = 0;
  countFailed: any = 0;

  categoriesList: any = [
    { id: 1, label: 'eVoucher' },
    { id: 2, label: 'eWallet' },
    { id: 3, label: 'PLN' },
    { id: 4, label: 'Pulsa' },
    { id: 5, label: 'Goods' }
  ]


  constructor(private jsLoaderService: JsLoaderService,
    private dashboardService: DashboardService,
    private logService: LogService,
    private customLocalStorage: CustomLocalStorage
  ) { };

  ngOnInit(): void {
    this.loadScripts();
    this.getDataCust();
    this.getDataRedemption();
    this.getFavProduct();
    this.getDataPending();

    // this.createChart();

  }


  private loadScripts() {
    // You can load multiple scripts by just providing the key as argument into load method of the service
    this.jsLoaderService.load(
      'custom-slick',
      'apex-chart1',
      'apex-chart',
      'stock-prices',
      'chart-custom1',
    ).then(data => {
      console.log(JSON.stringify(data));
    }).catch(error => console.log(error));
  }

  createChart() {
    this.chart = new Chart("MyChart", {
      type: 'line',
      data: {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        datasets: [
          {
            label: "Order Failed",
            data: this.dataOrderFailedFinal,
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderWidth: 2,
            pointBackgroundColor: 'rgb(255, 99, 132)',
          },
          {
            label: "Order Success",
            data: this.dataOrderSuccessFinal,
            borderColor: '#0DA487',
            backgroundColor: '#0DA487',
            borderWidth: 2,
            pointBackgroundColor: '#0DA487',
          }
        ]
      },
      options: {
        aspectRatio: 2.5,
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              color: 'rgba(0, 0, 0, 0.1)',
            }
          },
          x: {
            grid: {
              color: 'rgba(0, 0, 0, 0.1)',
            }
          }
        },
        plugins: {
          tooltip: {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            bodyFont: {
              size: 14,
            },
            titleFont: {
              size: 16,
              weight: 'bold',
            }
          },
          legend: {
            labels: {
              font: {
                size: 14,
              }
            }
          }
        }
      }
    });
  }


  ngOnDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  getDataCust() {
    this.dashboardService.getDashboarCustomer().subscribe({
      next: (res: any) => {
        this.totalCustomers = res.data.total_customers;
        this.custRegistToday = res.data.total_customers_today;
      }
    })
  }

  getDataRedemption() {
    this.dashboardService.getDashboardRedemption().subscribe({
      next: (res: any) => {
        this.totalOrders = res.data.total_redemptions;
        this.totalOrdersProgress = res.data.total_redemptions_progress;
      }
    })
  }

  getFavProduct() {
    this.dataOrderFailedFinal = Array(this.month).fill(0);
    this.dataOrderSuccessFinal = Array(this.month).fill(0);
    this.dashboardService.getDashboardFavProduct().subscribe({
      next: (res: any) => {
        console.log(res.data);
        this.dataProductFav = res.data;
        console.log(this.month)
        // this.dataOrderFailed.length = this.month;
        // this.dataOrderSuccess.length = this.month;
        this.dataProductFav.forEach((x: any, i: any) => {
          if (i % 2 == 0) {
            this.dataOrderSuccess.push(x.total_order);
          }
          else {
            this.dataOrderFailed.push(x.total_order);
          }
        });

        this.dataOrderSuccess.forEach((y: any, i: any) => {
          this.dataOrderSuccessFinal[i % this.dataOrderSuccessFinal.length] = this.dataOrderSuccess[i] + this.dataOrderSuccessFinal[i % this.dataOrderSuccessFinal.length];
        });

        this.dataOrderFailed.forEach((y: any, i: any) => {
          this.dataOrderFailedFinal[i % this.dataOrderFailedFinal.length] = this.dataOrderFailed[i] + this.dataOrderFailedFinal[i % this.dataOrderFailedFinal.length];
        });

        this.dataOrderSuccessFinal.forEach((z: any) => {
          this.countSuccess = this.countSuccess + z;
        });

        this.dataOrderFailedFinal.forEach((z: any) => {
          this.countFailed = this.countFailed + z;
        })

        console.log(this.dataOrderSuccessFinal);

        this.createChart();

      }
    })
  }

  getDataPending() {
    this.logService.getLog(this.customLocalStorage.getItem('role_id')).subscribe(
      (res: any) => {
        if (res.data == undefined) {
          this.dataPending = 0
        }
        else {
          this.dataPending = res.data;
        }

      }
    );

  }

}

export class LetterCase {
    constructor() {
    }

    /**
     *
     * @param word
     * @param splitSeparator // example '-', default space;
     * @param joinSeparator // example '-', if not defined it will be equated with splitSeparator or space
     */
    capitalizeEachWord(word:string, splitSeparator?:any, joinSeparator?:any) {
        if (![undefined, null, ' '].includes(word)) {
            let splitSptr = splitSeparator || ' ',
                joinSptr = joinSeparator || splitSeparator || ' ',
                arrWord = word.split(splitSptr),
                newArrWord = arrWord;

            newArrWord = arrWord.map((x) => {
                if (x.length > 0) x = x[0].toUpperCase() + x.substring(1);
                return x;
            });
            return newArrWord.join(joinSptr);
        }
        return;
    }
}
import { Injectable } from "@angular/core";

@Injectable
({
    providedIn: 'root'
})

export class Arr {

    public unset = [undefined, null, ''];
    removeByIndex(array: any[], index:number) {
        if (index > -1) {
            array.splice(index, 1);
        }
        return array;
    }
}
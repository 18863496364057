
    <!-- tap on top start -->
    <div class="tap-top">
        <span class="lnr lnr-chevron-up"></span>
    </div>
    <!-- tap on tap end -->

    <!-- page-wrapper Start-->
    <div class="page-wrapper compact-wrapper" id="pageWrapper">
        
        <app-topbar></app-topbar>

        <!-- Page Body Start-->
        <div class="page-body-wrapper">
            
            <app-sidebar></app-sidebar>

            <!-- index body start -->
            <div class="page-body">
                <router-outlet></router-outlet>
                <!-- Container-fluid Ends-->

                <app-footer></app-footer>
            </div>
            <!-- index body end -->

        </div>
        <!-- Page Body End -->
    </div>
    <!-- page-wrapper End-->
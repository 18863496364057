import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActivityLogComponent } from './activity-log/activity-log.component';
import { DashboardComponent } from './dashboard/dashboard.component';

const routes: Routes = [
  // new-menu
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'approval', loadChildren: () => import('./approval/approval.module').then(m => m.ApprovalModule) },
  { path: 'customer', loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule) },
  { path: 'level-management', loadChildren: () => import('./level-management/level-management.module').then(m => m.LevelManagementModule) },
  { path: 'pocket-management', loadChildren: () => import('./wallet-management/wallet-management.module').then(m => m.WalletManagementModule) },
  { path: 'product-management', loadChildren: () => import('./product-management/product-management.module').then(m => m.ProductManagementModule) },
  { path: 'input-files', loadChildren: () => import('./input-files/input-files.module').then(m => m.InputFilesModule) },
  { path: 'merchant-manager', loadChildren: () => import('./merchant-manager/merchant-manager.module').then(m => m.MerchantManagerModule) },
  { path: 'earning-rules', loadChildren: () => import('./earning-rules/earning-rules.module').then(m => m.EarningRulesModule) },
  { path: 'audit-trail-report', loadChildren: () => import('./audit-trail-report/audit-trail-report.module').then(m => m.AuditTrailReportModule) },
  { path: 'config', loadChildren: () => import('./config/config.module').then(m => m.ConfigModule) },
  { path: 'channel-management', loadChildren: () => import('./channel-management/channel-management.module').then(m => m.ChannelManagementModule) },
  { path: 'reward-management', loadChildren: () => import('./reward-management/reward-management.module').then(m => m.RewardManagementModule) },
  { path: 'campaign-management', loadChildren: () => import('./campaign-management/campaign-management.module').then(m => m.CampaignManagementModule) },
  { path: 'branch-management', loadChildren: () => import('./branch-management/branch-management.module').then(m => m.BranchManagementModule) },
  { path: 'content-management', loadChildren: () => import('./content-management/content-management.module').then(m => m.ContentManagementModule) },

  // old-menu
  { path: 'transaction', loadChildren: () => import('./transaction/transaction.module').then(m => m.TransactionModule) },
  { path: 'credit-card', loadChildren: () => import('./credit-card/credit-card.module').then(m => m.CreditCardModule) },
  { path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule) },
  { path: 'master', loadChildren: () => import('./master/master.module').then(m => m.MasterModule) },
  { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule) }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ModulesRoutingModule { }

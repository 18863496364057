                <!-- footer start-->
                <div class="container-fluid">
                    <footer class="footer">
                        <div class="row">
                            <div class="col-md-12 footer-copyright nunito-sans font-weight-600">
                                <p class="ml-3 mb-0">© 2023 Alpa Loyalty powered by Alpabit</p>
                            </div>
                        </div>
                    </footer>
                </div>
                <!-- footer End-->
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   apiUrl : 'http://alpaloyalty.localhost/api/',
//   apiMerchantUrl : 'https://alpamgw.alpabit.com/api/',
//   saltKey: 'mbi_angular',
// };

let config:any = (window as any).appConfig;
console.log(config)
export const environment = config.dev

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { Router } from "@angular/router";
import { CustomLocalStorage } from "../custom-storage/custom.local.storage";
import { CustomSessionStorage } from "../custom-storage/custom.session.storage";

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
    constructor(
        private router : Router,
        private customLocalStorage: CustomLocalStorage,
        private customSessionStorage: CustomSessionStorage
    ) {

    }
    intercept( request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // action interceptor
        // setTimeout(() => {
        //     window.location.reload(); 
           
        // }, 5000);

        let requestUrl = request.url;
            /* Set Base Url */
            request = request.clone({ url: environment.apiUrl + request.url });

            /* Set Headers */
            // const id_token = this.customSessionStorage.getItem(window.btoa('admin_id_token')) || '';
            // let token: any = AES.decrypt(id_token, 'password').toString(enc.Utf8);   
            let token = this.customLocalStorage.getItem('admin_id_token') || '';
            if (token) {
                request = request.clone({
                    headers: request.headers.set('Authorization', 'Bearer ' + token)
                });
            }
            else if(token == null || token == '' || token == undefined){
                this.router.navigate(['/login']);
            }


        return next.handle(request);
    }
}

// @Injectable()
// export class AppHttpInterceptors implements HttpInterceptor {
//     constructor() {}
//     intercept( request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

//         // action interceptor
//         /* Set Base Url */
//         request = request.clone({ url: environment.apiMerchantUrl + request.url })
        

//         /* Set Headers */
//         let token: any = this.customLocalStorage.getItem('token_merchant');
//         if (token) {
//             request = request.clone({
//                 headers: request.headers.set('Authorization', token)
//             });
//         }

//         return next.handle(request);
//     }
// }

export const codeName: any = {
    all: 'ALL',
    billCycle: 'BILL-CYCLE',
    bin: 'BIN',
    cardType: 'CARD-TYPE',
    productType: 'PRODUCT-CODE',
    pocketType: 'WALLET',
    general_transaction: 'General Transaction',
    event_rule: 'Event Rule',

    cc: 'CC',
    country: 'COUNTRY',
    currency: 'CURRENCY',
    customerBirthday: 'CUSTOMER-BIRTHDAY',
    daily: 'DAILY',
    earlyDate: '2020-01-01',
    equal: 'EQUAL',
    fixed: 'FIXED',
    ilike: 'ILIKE',
    level: 'LEVEL',
    like: 'LIKE',
    mcc: 'MCC',
    merchantName: 'MERCHANT-NAME',
    monthly: 'MONTHLY',
    multiplier: 'MULTIPLIER',
    nonCC: 'NONCC',
    nonUpay: 'NONUPAY',
    notEqual: 'NOT-EQUAL',
    onlineOffline: 'ONLINE-OFFLINE',
    percentage: 'PERCENTAGE',
    sourceTrx: 'SOURCE-TRX',
    true: 'TRUE',
    unlimitedDate: '2999-12-31',
    unlimitedNumber: 999999,
    upay: 'UPAY',
    weekly: 'WEEKLY',
    welcomeBonus: 'WELCOME-BONUS',
    yearly: 'YEARLY'
}

export const stateRef: any = {
    create: 'create',
    delete: 'delete',
    detail: 'detail',
    update: 'update',
    updateStatus: 'update-status',
    mapping: 'mapping'
}
import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { Arr } from "../../../core/arr/arr";

@Component({
  selector: 'multi-select-group',
  templateUrl: './multi-select-group.html',
  styleUrls: ['./multi-select-group.css']
})
export class MultiSelectGroup {
  @Input() data?: multiselectdata[];
  @Input() selected?: any[];
  @Input() className?: string;
  @Input() disabled?: boolean;
  @Input() readonly?: boolean;
  @Input() placeholder?: string;
  @Input() disableGroup?: boolean = false;
  @Output() selectedEvent = new EventEmitter<string>();
  @HostListener('document:click', ['$event'])
  clickout(event:any) {
    let dropdown = document.querySelector('#multiselect-dropdown');
    let display = document.querySelector('#multiselect-display');
    if (!(dropdown && dropdown.contains(event.target)) && this.isShown) {
      this.isShown = false;
      return;
    }
    if (display && display.contains(event.target) && !this.isShown && !this.disabled && !this.readonly) {
      if (!event.target.matches('i.ri-close-line')) {
        this.isShown = true;
      }
    }
  }

  public formSearch = new FormGroup({
    search: new FormControl()
  })

  public isShown = false;
  public dataControl?: multiselectdata[] = this.data;

  constructor(protected arr: Arr) {}
  ngOnInit() {
    this.dataControl = this.data;
  }

  setSelected(value: any) {
    this.selectedEvent.emit(value);
  }

  selectOption(value: any) {
    if (!this.selected)  this.selected = [];
    if (this.selected.includes(value)) {
      this.selected = this.selected.filter( (x: any) => {
        return x!==value;
      });
    } else {
      this.selected.push(value);
    }
    this.setSelected(this.selected);
    this.isShown = false;
  }

  map(select:any) {
    let label;
    this.dataControl?.forEach( (x: any) => {
      if (x.value==select) label = x.label;
    });
    return label;
  }

  remove(value: any) {
    if (!this.disabled && !this.readonly) this.selected = this.selected?.filter((x) => {
      return x!==value;
    });
    this.setSelected(this.selected);
  }

  search() {
    let keyword = this.formSearch.controls.search.value?.toLowerCase();
    this.data = this.dataControl?.filter( (x: any) => {
      return x.label.toLowerCase().indexOf(keyword) > -1;
    });
  }

  groupBy(option: any) {
    return option.group;
  }
}

export interface multiselectdata {
  value: any,
  label: any,
  group: any
}
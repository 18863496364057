import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';

import { environment } from './environments/environment';
import { AppHttpInterceptor } from './core/helpers/app.http.interceptor';
import { LayoutsModule } from './layouts/layouts.module';
import { ModulesModule } from './modules/modules.module';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DirectivesModule } from "./core/directives/directives.module";
import { Validation } from "./core/validation/validation";
import { AppMap } from "./core/map/app.map";
import { FlashSnackbar } from "./core/flash-snackbar/flash.snackbar";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CustomLocalStorage } from "./core/custom-storage/custom.local.storage";
import { CustomSessionStorage } from "./core/custom-storage/custom.session.storage";
import { LetterCase } from "./core/letter-case/letter.case";
import { Obj } from "./core/obj/obj";
import { Arr } from "./core/arr/arr";

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        DirectivesModule,
        LayoutsModule,
        ModulesModule,
        SharedModule,
        HttpClientModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
        }),
    ],
    providers: [
        AppMap,
        Arr,
        CustomLocalStorage,
        CustomSessionStorage,
        FlashSnackbar,
        LetterCase,
        MatSnackBar,
        Obj,
        Validation,
        { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API } from 'src/app/api-url';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private http : HttpClient
  ) { }

  getDashboarCustomer(){
    return this.http.get(`${API.base}/dashboard/customer`);
  }

  getDashboardRedemption(){
    return this.http.get(`${API.base}/dashboard/redemption`);
  }

  getDashboardFavProduct(){
    return this.http.get(`${API.base}/dashboard/redemption/product`);
  }
}

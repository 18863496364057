import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LayoutComponent } from '../layout.component';
import { ActivatedRoute } from '@angular/router';
import { LogService } from 'src/app/services/log/log.service';
import { CustomLocalStorage } from "../../core/custom-storage/custom.local.storage";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menu: any;
  menuCampaign: any;
  data: any
  constructor(
    protected auth: AuthService,
    protected app: LayoutComponent,
    private App : LayoutComponent,
    private get_menu: LayoutComponent,
    private route: ActivatedRoute,
    private logService: LogService,
    private customLocalStorage: CustomLocalStorage,


  ) { }

  ngOnInit(): void {
    this.menu = sessionStorage.getItem('menu');
    // console.log(this.App.getRole());
    // this.data this.app.getRole();
    this.getRole()
    // this.startRolePolling();


    // console.log(this.menu);

  }

  toDashboard() {
    window.location.href = '/dashboard';
  }

  getMenuCampaign(state: any) {
    this.menuCampaign = state;
    sessionStorage.setItem('stateCampaign', state);
    // console.log(this.menuCampaign);
  }

  getRole() {
    this.logService.getLog(this.customLocalStorage.getItem('role_id')).subscribe(
      (res: any) => {
        if (res.data == undefined) {
          this.data = 0
        }
        else {
          this.data = res.data;
        }
        
      }
    );
    

  }

  startRolePolling() {
    this.getRole(); 
    setInterval(() => {
      this.getRole();
    }, 5000); 
  }

}

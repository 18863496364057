import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { ModulesRoutingModule } from './modules-routing.module';
import { ActivityLogComponent } from './activity-log/activity-log.component';
import { UiModule } from '../shared/ui/ui.module';
import { DashboardComponent } from './dashboard/dashboard.component';


@NgModule({
  declarations: [
    ActivityLogComponent,
    DashboardComponent
  ],
  imports: [
    CommonModule,
    ModulesRoutingModule,
    UiModule,
    
  ]
})
export class ModulesModule { }

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomLocalStorage } from "../../core/custom-storage/custom.local.storage";
import { AuthService } from 'src/app/services/auth/auth.service';
import { LayoutComponent } from '../layout.component';

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit {
    loading: any;
    menu: any;
    constructor(
        private customLocalStorage: CustomLocalStorage,
        private router: Router,
        private authService: AuthService,
        protected auth: AuthService,
        protected menu_current: LayoutComponent,
        private get_menu : LayoutComponent
    ) { }

    ngOnInit(): void {
        this.menu = this.get_menu.getMenuCurrent();
        console.log(this.menu);
        // this.menu = sessionStorage.getItem('menu');
        // console.log(this.menu);
    }

    logoutBtn = () => {
        this.loading = true;
        this.authService.logOutRevoke().subscribe({
            next: (res: any) => {
                this.customLocalStorage.clear();
                localStorage.clear();
                sessionStorage.clear();
                window.location.href = '/login'
                // this.router.navigateByUrl('/login');
            }
        })

    };

    // getMenu(item: any) {
    //     // console.log(item);
    //     sessionStorage.setItem('menu', item);
    // }
}
import { Component, OnInit } from '@angular/core';
import { JsLoaderService } from '../shared/services/js-loader.service';
import { LogService } from '../services/log/log.service';
import { CustomLocalStorage } from '../core/custom-storage/custom.local.storage';
declare let feather: any;
declare let SimpleBar: any;
declare let $: any;

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {
menu : any;
public data : any;
  constructor(private jsLoaderService: JsLoaderService, 
    private logService : LogService, 
    private customLocalStorage : CustomLocalStorage
  ) {}

  ngOnInit(): void {
    this.loadScripts();
  }

  private loadScripts() {
    // You can load multiple scripts by just providing the key as argument into load method of the service
    this.jsLoaderService.load(
      // 'jquery',
      'bootstrap',
      'feather',
      'scrollbar',
      'config',
      'sidebar-menu',
      'moment',
      'slick',
      'sidebar-effect',
    ).then(data => {

      feather.replace();

      var myElement = document.getElementById('simple-bar');
      new SimpleBar(myElement, {
          autoHide: true
      });

      //page loader
      $(window).on('load', function () {
        setTimeout(function () {
            $('.loader-wrapper').fadeOut('slow');
        }, 1000);
        $('.loader-wrapper').remove('slow');
      });

      console.log(JSON.stringify(data));

    }).catch(error => console.log(error));
  }

  getMenu(item : any){
    sessionStorage.setItem('menu', item);
  }

  getMenuCurrent(){
    this.menu = sessionStorage.getItem('menu');
    return this.menu;
  }
}

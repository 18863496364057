import { Component } from '@angular/core';
import { AuthService } from "./services/auth/auth.service";
import { Router } from "@angular/router";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'Backoffice';

    constructor(
        private auth: AuthService,
        private router: Router
    ) {
    }
    goToPendingApproval(alternatif_direction?:any) {
        if (this.auth.isHasPermission('view', 'pending_approval')) {
            this.router.navigate(['approval/list']);
        } else {
            alternatif_direction? alternatif_direction : '';
            return
        }
    }

    getDateCurrent(value : any){
        var timestamps = new Date(value);
          let years: any = timestamps.getFullYear();
          let months: any = timestamps.getMonth() + 1;
          let dts: any = timestamps.getDate();
          let hours: any = timestamps.getHours();
          let minutes: any = timestamps.getMinutes();
          let seconds: any = timestamps.getSeconds();

          if (dts < 10) {
            dts = '0' + dts;
          }
          if (months < 10) {
            months = '0' + months;
          }
          if (hours < 10) {
            hours = '0' + hours;
          }
          if (minutes < 10) {
            minutes = '0' + minutes;
          }
          return dts + '-' + months + '-' + years + ' ' + hours + ':' + minutes + ':' + (seconds >= 0 && seconds < 10 ? `0${seconds}` : seconds);
    }
}
import {AES, enc} from "crypto-ts";
import {environment} from "../../environments/environment";

export class CustomSessionStorage {
    public length:number = sessionStorage.length;

    constructor() {
    }

    setItem(key:string, value:any) {
        sessionStorage.setItem(window.btoa(key), AES.encrypt(value, environment.saltKey).toString());
    }

    getItem(key:string) {
        try {
            return AES.decrypt(sessionStorage.getItem(window.btoa(key)) as string, environment.saltKey).toString(enc.Utf8);
        } catch (e) {
            return sessionStorage.getItem(window.btoa(key));
        }
    }

    key(index:number) {
        return sessionStorage.key(index);
    }

    removeItem(key:string) {
        sessionStorage.removeItem(window.btoa(key));
    }

    clear() {
        sessionStorage.clear();
    }
}
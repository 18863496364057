<div class="container-fluid">
    <div class="row">
        <div class="spinner-border Typeahead-spinner" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <!-- chart caard section start -->
        <div class="col-sm-6 col-xxl-3 col-lg-6">
            <div class="main-tiles border-5 border-0  card-hover card o-hidden">
                <div class="custome-2-bg b-r-4 card-body">
                    <div class="media align-items-center static-top-widget">
                        <div class="align-self-center text-center"
                            style="margin-left: 0px !important; width: 60px; height: 60px;">
                            <i class="ri-sticky-note-2-fill"></i>
                        </div>
                        <div class="media-body p-0 ms-3">
                            <div style="font-size: large; font-weight: 600;">{{dataPending}} <span class="m-0"
                                    style="font-size: large;">Data</span></div>
                            <h4 class="mb-0 counter">
                                <span class="badge badge-light-danger grow">
                                    Total Pending Approval</span>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="col-sm-6 col-xxl-3 col-lg-6">
            <div class="main-tiles border-5 card-hover border-0 card o-hidden">
                <div class="custome-2-bg b-r-4 card-body">
                    <div class="media static-top-widget">
                        <div class="align-self-center text-center"
                            style="margin-left: 0px !important; width: 60px; height: 60px;">
                            <i class="ri-shopping-bag-2-fill"></i>
                        </div>

                        <div class="media-body p-0 ms-3">
                            <div style="font-size: large; font-weight: 600;">{{totalProducts}} <span class="m-0"
                                    style="font-size: large;">Products</span></div>
                            <h4 class="mb-0 counter">
                                <span class="badge badge-light-danger grow">
                                    Available Products</span>
                            </h4>
                        </div>

                    </div>
                </div>
            </div>
        </div> -->

        <!-- <div class="col-sm-6 col-xxl-3 col-lg-6">
            <div class="main-tiles border-5 card-hover border-0  card o-hidden">
                <div class="custome-3-bg b-r-4 card-body">
                    <div class="media static-top-widget">
                        <div class="align-self-center text-center"
                            style="margin-left: 0px !important; width: 60px; height: 60px;">
                            <i class="ri-shopping-cart-2-fill"></i>
                        </div>

                        <div class="media-body p-0 ms-3">
                            <div style="font-size: large; font-weight: 600;">{{totalOrders}} <span class="m-0"
                                    style="font-size: large;">Orders</span></div>
                            <h4 class="mb-0 counter">
                                <span class="badge badge-light-secondary grow">
                                    {{totalOrdersProgress}} Order on Progres</span>
                            </h4>
                        </div>

                    </div>
                </div>
            </div>
        </div> -->

        <div class="col-sm-6 col-xxl-3 col-lg-6">
            <div class="main-tiles border-5 card-hover border-0  card o-hidden">
                <div class="custome-3-bg b-r-4 card-body">
                    <div class="media static-top-widget">
                        <div class="align-self-center text-center"
                            style="margin-left: 0px !important; width: 60px; height: 60px;">
                            <i class="ri-shopping-cart-2-fill"></i>
                        </div>

                        <div class="media-body p-0 ms-3">
                            <div style="font-size: large; font-weight: 600;">{{countFailed}} <span class="m-0"
                                    style="font-size: large;">Orders Failed</span></div>
                            <h4 class="mb-0 counter">
                                <span class="badge badge-light-secondary grow">
                                    {{totalOrders}} Total Order</span>
                            </h4>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-6 col-xxl-3 col-lg-6">
            <div class="main-tiles border-5 card-hover border-0  card o-hidden">
                <div class="custome-1-bg b-r-4 card-body">
                    <div class="media static-top-widget">
                        <div class="align-self-center text-center"
                            style="margin-left: 0px !important; width: 60px; height: 60px;">
                            <i class="ri-shopping-cart-2-fill"></i>
                        </div>

                        <div class="media-body p-0 ms-3">
                            <div style="font-size: large; font-weight: 600;">{{countSuccess}} <span class="m-0"
                                    style="font-size: large;">Orders Success</span></div>
                            <h4 class="mb-0 counter">
                                <span class="badge badge-light-primary grow">
                                    {{totalOrders}} Total Order</span>
                            </h4>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-6 col-xxl-3 col-lg-6">
            <div class="main-tiles border-5 card-hover border-0 card o-hidden">
                <div class="custome-4-bg b-r-4 card-body">
                    <div class="media static-top-widget">
                        <div class="align-self-center text-center"
                            style="margin-left: 0px !important; width: 60px; height: 60px;">
                            <i class="ri-team-fill"></i>
                        </div>

                        <div class="media-body p-0 ms-3">
                            <div style="font-size: large; font-weight: 600;">{{totalCustomers}} <span class="m-0"
                                    style="font-size: large;">Customers</span></div>
                            <h4 class="mb-0 counter">
                                <span class="badge badge-light-success grow">
                                    {{custRegistToday}} Registered Today
                                </span>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="col-12">
            <div class="card o-hidden card-hover">
                <div class="card-header border-0 pb-1">
                    <div class="card-header-title p-0">
                        <h4>Transaction Status</h4>
                    </div>
                </div>
                <div class="card-body p-0"
                    style="width: (5*1000)px; overflow-x: scroll; text-align: center; justify-content: center;">
                    <div class="d-flex flex-row mb-3">
                        <div style="width: 300px;">
                            <div class="media static-top-widget"
                                style="padding: 20px; border-right: 2px solid #0da487; height: 120px;">
                                <div class="align-self-center text-center"
                                    style="margin-left: 0px !important; width: 60px; height: 60px;">
                                    <a class="category-image">
                                        <img src="../../../assets/assets/images/packing_process.png" alt="">
                                    </a>
                                </div>

                                <div class="media-body p-0 ms-3">
                                    <div style="font-size: large; font-weight: 600;">Packing Process</div>
                                    <div>item</div>
                                </div>
                            </div>
                        </div>

                        <div style="width: 300px;">
                            <div class="media static-top-widget"
                                style="padding: 20px; border-right: 2px solid #0da487; height: 120px;">
                                <div class="align-self-center text-center"
                                    style="margin-left: 0px !important; width: 60px; height: 60px;">
                                    <a class="category-image">
                                        <img src="../../../assets/assets/images/pick_up.png" alt="">
                                    </a>
                                </div>

                                <div class="media-body p-0 ms-3">
                                    <div style="font-size: large; font-weight: 600;">Pick Up</div>
                                    <div>item</div>
                                </div>
                            </div>
                        </div>

                        <div style="width: 300px;">
                            <div class="media static-top-widget"
                                style="padding: 20px; border-right: 2px solid #0da487; height: 120px;">
                                <div class="align-self-center text-center"
                                    style="margin-left: 0px !important; width: 60px; height: 60px;">
                                    <a class="category-image">
                                        <img src="../../../assets/assets/images/delivery_process.png" alt="">
                                    </a>
                                </div>

                                <div class="media-body p-0 ms-3">
                                    <div style="font-size: large; font-weight: 600;">Delivery Process</div>
                                    <div>item</div>
                                </div>
                            </div>

                        </div>

                        <div style="width: 300px;">
                            <div class="media static-top-widget"
                                style="padding: 20px; border-right: 2px solid #0da487; height: 120px;">
                                <div class="align-self-center text-center"
                                    style="margin-left: 0px !important; width: 60px; height: 60px;">
                                    <a class="category-image">
                                        <img src="../../../assets/assets/images/Deliveried.png" alt="">
                                    </a>
                                </div>

                                <div class="media-body p-0 ms-3">
                                    <div style="font-size: large; font-weight: 600;">Deliveried</div>
                                    <div>item</div>
                                </div>
                            </div>

                        </div>

                        <div style="width: 300px;">
                            <div class="media static-top-widget" style="padding: 20px; height: 120px;">
                                <div class="align-self-center text-center"
                                    style="margin-left: 0px !important; width: 60px; height: 60px;">
                                    <a class="category-image">
                                        <img src="../../../assets/assets/images/pending.png" alt="">
                                    </a>
                                </div>

                                <div class="media-body p-0 ms-3">
                                    <div style="font-size: large; font-weight: 600;">Pending</div>
                                    <div>item</div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div> -->
        <!-- chart card section End -->


        <!-- Earning chart star-->
        <div class="col-xl-6">
            <div class="card o-hidden card-hover" style="height: 400px;">
                <div class="card-header border-0 pb-1">
                    <div class="card-header-title">
                        <h4>Active Transaction</h4>
                    </div>
                </div>
                <div class="card-body p-0">
                    <div class="chart-container">
                        <canvas id="MyChart">{{ chart }}</canvas>
                    </div>
                    <!-- <div id="bar-chart-earning"></div> -->
                </div>
            </div>
        </div>
        <!-- Earning chart  end-->


        <!-- Best Selling Product Start -->
        <div class="col-xl-6 col-md-12">
            <div class="card o-hidden card-hover" style="height: 400px;">
                <div class="card-header card-header-top card-header--2 px-0 pt-0">
                    <div class="card-header-title">
                        <h4>Top Categories</h4>
                    </div>

                    <!-- <div class="best-selling-box d-sm-flex d-none">
                        <span>Short By:</span>
                        <div class="dropdown">
                            <button class="btn p-0 dropdown-toggle" type="button" id="dropdownMenuButton1"
                                data-bs-toggle="dropdown" data-bs-auto-close="true">Today</button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><a class="dropdown-item" href="#">Action</a></li>
                                <li><a class="dropdown-item" href="#">Another action</a></li>
                                <li><a class="dropdown-item" href="#">Something else here</a></li>
                            </ul>
                        </div>
                    </div> -->
                </div>

                <div class="card-body p-0" style="overflow-y: scroll;">
                    <div>
                        <div class="table-responsive">
                            <table class="best-selling-table w-image
                            w-image
                            w-image table border-0">
                                <tbody>
                                    <tr *ngFor="let item of categoriesList">
                                        <td>
                                            <div class="best-product-box">
                                                <div class="product-image">
                                                    <img src="assets/images/product/image-voucher.png" class="img-fluid"
                                                        alt="Product">
                                                </div>
                                                <div class="product-name">
                                                    <h5>{{item.label}}</h5>
                                                    <!-- <h6>{{item.partner_name}}</h6> -->
                                                </div>
                                            </div>
                                        </td>

                                        <!-- <td>
                                            <div class="product-detail-box">
                                                <h6>Price</h6>
                                                <h5>{{item.price}}</h5>
                                            </div>
                                        </td>

                                        <td>
                                            <div class="product-detail-box">
                                                <h6>Orders</h6>
                                                <h5>{{item.total_order}}</h5>
                                            </div>
                                        </td>

                                        <td>
                                            <div class="product-detail-box">
                                                <h6>Stock</h6>
                                                <h5>{{item.total_quantity}}</h5>
                                            </div>
                                        </td> -->

                                        <!-- <td>
                                            <div class="product-detail-box">
                                                <h6>Amount</h6>
                                                <h5>$1,798</h5>
                                            </div>
                                        </td> -->
                                    </tr>


                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Best Selling Product End -->


        <!-- Recent orders start-->
        <!-- <div class="col-xl-6">
            <div class="card o-hidden card-hover">
                <div class="card-header card-header-top card-header--2 px-0 pt-0">
                    <div class="card-header-title">
                        <h4>Recent Orders</h4>
                    </div>

                    <div class="best-selling-box d-sm-flex d-none">
                        <span>Short By:</span>
                        <div class="dropdown">
                            <button class="btn p-0 dropdown-toggle" type="button" id="dropdownMenuButton2"
                                data-bs-toggle="dropdown" data-bs-auto-close="true">Today</button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                <li><a class="dropdown-item" href="#">Action</a></li>
                                <li><a class="dropdown-item" href="#">Another action</a></li>
                                <li><a class="dropdown-item" href="#">Something else here</a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="card-body p-0">
                    <div>
                        <div class="table-responsive">
                            <table class="best-selling-table table border-0">
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="best-product-box">
                                                <div class="product-name">
                                                    <h5>Aata Buscuit</h5>
                                                    <h6>#64548</h6>
                                                </div>
                                            </div>
                                        </td>

                                        <td>
                                            <div class="product-detail-box">
                                                <h6>Date Placed</h6>
                                                <h5>5/1/22</h5>
                                            </div>
                                        </td>

                                        <td>
                                            <div class="product-detail-box">
                                                <h6>Price</h6>
                                                <h5>$250.00</h5>
                                            </div>
                                        </td>

                                        <td>
                                            <div class="product-detail-box">
                                                <h6>Order Status</h6>
                                                <h5>Completed</h5>
                                            </div>
                                        </td>

                                        <td>
                                            <div class="product-detail-box">
                                                <h6>Payment</h6>
                                                <h5 class="text-danger">Unpaid</h5>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <div class="best-product-box">
                                                <div class="product-name">
                                                    <h5>Aata Buscuit</h5>
                                                    <h6>26-08-2022</h6>
                                                </div>
                                            </div>
                                        </td>


                                        <td>
                                            <div class="product-detail-box">
                                                <h6>Date Placed</h6>
                                                <h5>5/1/22</h5>
                                            </div>
                                        </td>

                                        <td>
                                            <div class="product-detail-box">
                                                <h6>Price</h6>
                                                <h5>$250.00</h5>
                                            </div>
                                        </td>

                                        <td>
                                            <div class="product-detail-box">
                                                <h6>Order Status</h6>
                                                <h5>Completed</h5>
                                            </div>
                                        </td>

                                        <td>
                                            <div class="product-detail-box">
                                                <h6>Payment</h6>
                                                <h5 class="theme-color">Paid</h5>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <div class="best-product-box">
                                                <div class="product-name">
                                                    <h5>Aata Buscuit</h5>
                                                    <h6>26-08-2022</h6>
                                                </div>
                                            </div>
                                        </td>


                                        <td>
                                            <div class="product-detail-box">
                                                <h6>Date Placed</h6>
                                                <h5>5/1/22</h5>
                                            </div>
                                        </td>

                                        <td>
                                            <div class="product-detail-box">
                                                <h6>Price</h6>
                                                <h5>$250.00</h5>
                                            </div>
                                        </td>

                                        <td>
                                            <div class="product-detail-box">
                                                <h6>Order Status</h6>
                                                <h5>Completed</h5>
                                            </div>
                                        </td>

                                        <td>
                                            <div class="product-detail-box">
                                                <h6>Payment</h6>
                                                <h5 class="theme-color">Paid</h5>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <div class="best-product-box">
                                                <div class="product-name">
                                                    <h5>Aata Buscuit</h5>
                                                    <h6>26-08-2022</h6>
                                                </div>
                                            </div>
                                        </td>


                                        <td>
                                            <div class="product-detail-box">
                                                <h6>Date Placed</h6>
                                                <h5>5/1/22</h5>
                                            </div>
                                        </td>

                                        <td>
                                            <div class="product-detail-box">
                                                <h6>Price</h6>
                                                <h5>$250.00</h5>
                                            </div>
                                        </td>

                                        <td>
                                            <div class="product-detail-box">
                                                <h6>Order Status</h6>
                                                <h5>Completed</h5>
                                            </div>
                                        </td>

                                        <td>
                                            <div class="product-detail-box">
                                                <h6>Payment</h6>
                                                <h5 class="theme-color">Paid</h5>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- Recent orders end-->

        <!-- Earning chart star-->
        <!-- <div class="col-xl-6">
            <div class="card o-hidden card-hover">
                <div class="card-header border-0 mb-0">
                    <div class="card-header-title">
                        <h4>Earning</h4>
                    </div>
                </div>
                <div class="card-body p-0">
                    <div id="bar-chart-earning"></div>
                </div>
            </div>
        </div> -->
        <!-- Earning chart end-->


        <!-- Transactions start-->
        <!-- <div class="col-xxl-4 col-md-6">
            <div class="card o-hidden card-hover">
                <div class="card-header border-0">
                    <div class="card-header-title">
                        <h4>Transactions</h4>
                    </div>
                </div>

                <div class="card-body pt-0">
                    <div>
                        <div class="table-responsive">
                            <table class="user-table transactions-table table border-0">
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="transactions-icon">
                                                <i class="ri-shield-line"></i>
                                            </div>
                                            <div class="transactions-name">
                                                <h6>Wallets</h6>
                                                <p>Starbucks</p>
                                            </div>
                                        </td>

                                        <td class="lost">-$74</td>
                                    </tr>
                                    <tr>
                                        <td class="td-color-1">
                                            <div class="transactions-icon">
                                                <i class="ri-check-line"></i>
                                            </div>
                                            <div class="transactions-name">
                                                <h6>Bank Transfer</h6>
                                                <p>Add Money</p>
                                            </div>
                                        </td>

                                        <td class="success">+$125</td>
                                    </tr>
                                    <tr>
                                        <td class="td-color-2">
                                            <div class="transactions-icon">
                                                <i class="ri-exchange-dollar-line"></i>
                                            </div>
                                            <div class="transactions-name">
                                                <h6>Paypal</h6>
                                                <p>Add Money</p>
                                            </div>
                                        </td>

                                        <td class="lost">-$50</td>
                                    </tr>
                                    <tr>
                                        <td class="td-color-3">
                                            <div class="transactions-icon">
                                                <i class="ri-bank-card-line"></i>
                                            </div>
                                            <div class="transactions-name">
                                                <h6>Mastercard</h6>
                                                <p>Ordered Food</p>
                                            </div>
                                        </td>

                                        <td class="lost">-$40</td>
                                    </tr>
                                    <tr>
                                        <td class="td-color-4 pb-0">
                                            <div class="transactions-icon">
                                                <i class="ri-bar-chart-grouped-line"></i>
                                            </div>
                                            <div class="transactions-name">
                                                <h6>Transfer</h6>
                                                <p>Refund</p>
                                            </div>
                                        </td>

                                        <td class="success pb-0">+$90</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- Transactions end-->

        <!-- visitors chart start-->
        <!-- <div class="col-xxl-4 col-md-6">
            <div class="h-100">
                <div class="card o-hidden card-hover">
                    <div class="card-header border-0">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="card-header-title">
                                <h4>Visitors</h4>
                            </div>
                        </div>
                    </div>
                    <div class="card-body pt-0">
                        <div class="pie-chart">
                            <div id="pie-chart-visitors"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- visitors chart end-->


        <!-- To Do List start-->
        <!-- <div class="col-xxl-4 col-md-6">
            <div class="card o-hidden card-hover">
                <div class="card-header border-0">
                    <div class="card-header-title">
                        <h4>To Do List</h4>
                    </div>
                </div>

                <div class="card-body pt-0">
                    <ul class="to-do-list">
                        <li class="to-do-item">
                            <div class="form-check user-checkbox">
                                <input class="checkbox_animated check-it" type="checkbox" value=""
                                    id="flexCheckDefault">
                            </div>
                            <div class="to-do-list-name">
                                <strong>Pick up kids from school</strong>
                                <p>8 Hours</p>
                            </div>
                        </li>
                        <li class="to-do-item">
                            <div class="form-check user-checkbox">
                                <input class="checkbox_animated check-it" type="checkbox" value=""
                                    id="flexCheckDefault1">
                            </div>
                            <div class="to-do-list-name">
                                <strong>Prepare or presentation.</strong>
                                <p>8 Hours</p>
                            </div>
                        </li>
                        <li class="to-do-item">
                            <div class="form-check user-checkbox">
                                <input class="checkbox_animated check-it" type="checkbox" value=""
                                    id="flexCheckDefault2">
                            </div>
                            <div class="to-do-list-name">
                                <strong>Create invoice</strong>
                                <p>8 Hours</p>
                            </div>
                        </li>
                        <li class="to-do-item">
                            <div class="form-check user-checkbox">
                                <input class="checkbox_animated check-it" type="checkbox" value=""
                                    id="flexCheckDefault3">
                            </div>
                            <div class="to-do-list-name">
                                <strong>Meeting with Alisa</strong>
                                <p>8 Hours</p>
                            </div>
                        </li>

                        <li class="to-do-item">
                            <form class="row g-2">
                                <div class="col-8">
                                    <input type="text" class="form-control" id="name" placeholder="Enter Task Name">
                                </div>
                                <div class="col-4">
                                    <button type="submit" class="btn btn-primary w-100 h-100">Add
                                        task</button>
                                </div>
                            </form>
                        </li>
                    </ul>
                </div>
            </div>
        </div> -->
        <!-- To Do List end-->


    </div>
</div>
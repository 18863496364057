import { AES, enc } from "crypto-ts";
import { environment } from "../../environments/environment";

export class CustomLocalStorage {
    public length:number = localStorage.length;

    constructor() {
    }

    setItem(key:string, value:any) {
        localStorage.setItem(window.btoa(key), AES.encrypt(value, environment.saltKey).toString());
    }

    getItem(key:string) {
        try {
            return AES.decrypt(localStorage.getItem(window.btoa(key)) as string, environment.saltKey).toString(enc.Utf8);
        } catch (e) {
            return localStorage.getItem(window.btoa(key));
        }
    }

    key(index:number) {
        return localStorage.key(index);
    }

    removeItem(key:string) {
        localStorage.removeItem(window.btoa(key));
    }

    clear() {
        localStorage.clear();
    }

}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Validation } from "../core/validation/validation";
import { MultiSelect } from './multi-select/multi-select/multi-select';
import { MultiSelectGroup } from './multi-select/multi-select-group/multi-select-group';
import {ReactiveFormsModule} from "@angular/forms";



@NgModule({
  declarations: [
    MultiSelect,
    MultiSelectGroup
  ],
  exports: [
    MultiSelect,
    MultiSelectGroup
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule
    ]
})
export class SharedModule { }

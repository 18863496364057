<div class="multi-select" [ngClass]="{'readonly': readonly}">
    <div id="multiselect-display">
        <div *ngIf="selected? selected.length===0 : false" class="form-control empty"><span *ngIf="!arr.unset.includes(placeholder)" class="placeholder">{{placeholder}}</span></div>
        <div *ngIf="selected? selected.length>0 : false" class="form-control contain">
            <div class="list" *ngFor="let s of selected"><span><i class="ri-close-line" (click)="remove(s)"></i></span>{{map(s)}}</div>
        </div>
    </div>
    <div id="multiselect-dropdown" class="dropdown" [ngClass]="{className : className, 'show' : isShown}">
        <div class="p-2">
            <div [formGroup]="formSearch">
            <input class="form-control" formControlName="search" (keyup)="search()">
            </div>
        </div>
        <ul class="option">
            <li class="list" 
                [ngClass]="{
                    'active': selected?.includes(l.value), 'child': l.group && l.group !== '', 
                    'parent': !l.group || l.group === '' || l.group == 0, 
                    'disabled': (!l.group || l.group === '' || l.group == 0) && disableGroup
                }" (click)="selectOption(l.value)" *ngFor="let l of data" disabled>{{l.label}}</li>
        </ul>
    </div>
</div>
export class AppMap {

    statusMap: any = {
        0: 'REQUEST',
        1: 'APPROVED',
        2: 'VIEW',
        9: 'REJECTED'
    }
    status(statusCode:number) {
        return this.statusMap[statusCode];
    }
}
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API } from 'src/app/api-url';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  constructor(
    private http: HttpClient,
  ) { }

  getRoles(params: any) {
    return this.http.get(`${API.base}/role`, { params });
  }

  getAllRoles() {
    return this.http.get(`${API.base}/role/all`)
  }

  getRoleById(ID: any) {
    return this.http.get(`${API.base}/role/${ID}`);
  }

  addRole(body : any){
    return this.http.post(`${API.base}/role`, body);
  }

  getAccess(){
    return this.http.get(`${API.base}/listaccess`);
  }

  getModule(){
    return this.http.get(`${API.base}/listmenu`);
  }

  detailRole(id : any){
    return this.http.get(`${API.base}/role/${id}`);
  }

  updateRole(id : any, body : any){
    return this.http.post(`${API.base}/role/${id}`, body);
  }

  deleteRole(id : any){
    let formData = new FormData();
    formData.append('id', id);
    return this.http.post(`${API.base}/role/delete/${id}`, formData);
  }
}

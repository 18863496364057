import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumberOnlyDirective } from "./number-only/number-only.directive";
import { NumberOnlyCommaSeparatorDirective } from "./number-only-comma-separator/number-only-comma-separator.directive";
import { NumberOnlyDotSeparatorDirective } from "./number-only-dot-separator/number-only-dot-separator.directive";



@NgModule({
    declarations: [
        NumberOnlyDirective,
        NumberOnlyCommaSeparatorDirective,
        NumberOnlyDotSeparatorDirective
    ],
    exports: [
        NumberOnlyDirective,
        NumberOnlyCommaSeparatorDirective,
        NumberOnlyDotSeparatorDirective
    ],
    imports: [
        CommonModule
    ]
})
export class DirectivesModule { }

import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appNumberOnlyCommaSeparator]'
})
export class NumberOnlyCommaSeparatorDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event : KeyboardEvent) {

    const initalValue = this.el.nativeElement.value;
    let number = String(initalValue);
    let newNumber = number;
    if (number.split(",")) {
      newNumber = number.split(",").join("");
    }
    let onlyNumber = newNumber.replace(/[^0-9]/g, '');

    let num_parts = onlyNumber.toString().split(',');

    this.el.nativeElement.value = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    if ( initalValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}

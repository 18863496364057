import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API } from 'src/app/api-url';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  constructor(
    private http: HttpClient,
  ) { }

  getLog(id : any){
    return this.http.get(`${API.base}/ext/log/notification/${id}`);
  }

  getLogActivity(params : any){
    return this.http.get(`${API.admin}/log-activity/all`, {params : params});
  }

}

<!-- Page Header Start-->
<div class="page-header">
    <div class="header-wrapper m-0">
        <div class="header-logo-wrapper p-0">
            <div class="logo-wrapper">
                <a href="/">
                    <img class="img-fluid main-logo" src="assets/images/logo/1.png" alt="logo">
                    <img class="img-fluid white-logo" src="assets/images/logo/1-white.png" alt="logo">
                </a>
            </div>
            <div class="toggle-sidebar">
                <i class="status_toggle middle sidebar-toggle" data-feather="align-center"></i>
                <a>
                    <img src="assets/images/logo/1.png" class="img-fluid" alt="">
                </a>
            </div>
        </div>

        <!-- <form class="form-inline search-full" method="get">
            <div class="form-group w-100">
                <div class="Typeahead Typeahead--twitterUsers">
                    <div class="u-posRelative">
                        <input class="demo-input Typeahead-input form-control-plaintext w-100" type="text"
                            placeholder="Search Alpaloyalty ..." name="q" title="" autofocus>
                        <i class="close-search" data-feather="x"></i>
                        <div class="spinner-border Typeahead-spinner" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <div class="Typeahead-menu"></div>
                </div>
            </div>
        </form> -->

        <div class="nav-right pull-right right-header p-0">
            <ul class="nav-menus">

                <li class="onhover-dropdown">

                    <div class="media profile-media">
                        <i class="fa fa-user-circle" aria-hidden="true"></i>
                        <div class="user-name-hide media-body">
                            <span>Account</span>
                        </div>

                    </div>

                    <ul class="profile-dropdown onhover-show-div">
                        <li>
                            <a href="/settings/account" routerLinkActive="active"
                                (click)="menu_current.getMenu('account')">
                                <i data-feather="info"></i>
                                <span>Edit Your Account</span>
                            </a>
                        </li>
                        <li>
                            <a data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                <i data-feather="log-out"></i>
                                <span>Log out</span>
                            </a>
                        </li>
                    </ul>
                </li>

                <li class="onhover-dropdown">
                    <div class="media profile-media">
                        <i class="fa fa-cog" aria-hidden="true"></i>
                        <div class="user-name-hide media-body">
                            <span>Settings</span>
                        </div>

                    </div>

                    <ul class="profile-dropdown onhover-show-div">
                        <li *ngIf="auth.isHasPermission('view', 'user')">
                            <a href="/settings/user" routerLinkActive="active" (click)="menu_current.getMenu('user')">
                                <i data-feather="users"></i>
                                <span>Users</span>
                            </a>
                        </li>
                        <li *ngIf="auth.isHasPermission('view', 'role')">
                            <a href="/settings/role" routerLinkActive="active" (click)="menu_current.getMenu('role')">
                                <i data-feather="settings"></i>
                                <span>ACL</span>
                            </a>
                        </li>

                        <li *ngIf="auth.isHasPermission('view', 'role')">
                            <a href="/settings/translations" routerLinkActive="active">
                                <i data-feather="flag"></i>
                                <span>Translations</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div>
<!-- Page Header Ends-->



<!-- Modal Start -->
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body" *ngIf="!loading">
                <h5 class="modal-title" id="staticBackdropLabel">Logging Out</h5>
                <p>Are you sure you want to log out?</p>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <div class="button-box">
                    <button type="button" class="btn btn--no" data-bs-dismiss="modal">No</button>
                    <button type="button" class="btn  btn--yes btn-primary" data-bs-dismiss="modal"
                        (click)="logoutBtn();">Yes</button>
                </div>
            </div>
            <div class="modal" *ngIf="loading">
                <div class="d-flex justify-content-center">
                    <div class="spinner-border text-success" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal End -->
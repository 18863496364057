export class Validation {

    /**
     * Phone Validation
     */
    public isPhone: boolean = false;
    public invalidPhoneErrorMessage: string = '';
    public isPhoneValid(phone?:any) {
        if (phone) {
            let phoneStr = phone.toString();
            if (phone.length>1) {
                if (phone[0]==='0' && phone[1]==='8') {
                    if (phone.length>9) {
                        this.isPhone = true;
                        this.invalidPhoneErrorMessage = '';
                        return this.isPhone;
                    } else {
                        this.isPhone = false;
                        this.invalidPhoneErrorMessage = 'Phone range 10-13 numbers';
                        return this.isPhone;
                    }
                }
            }
        }
        this.isPhone = false;
        this.invalidPhoneErrorMessage = 'Invalid format phone';
        return this.isPhone;
    }

    /**
     * Email Validation
     */
    public isEmail: boolean = false;
    public invalidEmailErrorMessage: string = '';
    public isEmailValid(email?:any) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            this.isEmail = true;
            this.invalidEmailErrorMessage = '';
            return this.isEmail;
        }
        this.isEmail = false;
        this.invalidEmailErrorMessage = 'Invalid format email';
        return this.isEmail;
    }


    public isAlphabet: boolean = false;
    public invalidAlphabetErrorMessage: string = '';
    public isAlphabetValid(data?:any){
        if(/^[A-Za-z0-9]*$/.test(data)){
            console.log(data);
            this.isAlphabet = true;
            this.invalidAlphabetErrorMessage = '';
            return this.isAlphabet;
        }
        this.isAlphabet = false;
        this.invalidAlphabetErrorMessage = 'Invalid format name';
        return this.isAlphabet;
    }


    public isLetter: boolean = false;
    public invalidLetterErrorMessage: string = '';
    public isLetterValid(data?:any){
        if(/^[A-Za-z\s]*$/.test(data)){
            this.isLetter = true;
            this.invalidLetterErrorMessage = '';
            return this.isLetter;
        }
        this.isLetter = false;
        this.invalidLetterErrorMessage = 'Invalid format name';
        return this.isLetter;
    }
    
}
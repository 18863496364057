import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { API } from 'src/app/api-url';
import { map } from 'rxjs';
import { RoleService } from "../user/role.service";
import {CustomLocalStorage} from "../../core/custom-storage/custom.local.storage";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private http: HttpClient,
        private customLocalStorage: CustomLocalStorage,
        private roleService: RoleService
    ) { }

    login(body: any) {
        let self = this;
        return this.http.post(`${API.authLogin}`, body ).pipe(map((user: any) => {
            console.log(user.data);
            this.customLocalStorage.setItem('admin_id_token', user.data.token);
            this.customLocalStorage.setItem('id_user_account', String(user.data.id));
            this.customLocalStorage.setItem('role_id', String(user.data.role_id));

            self.roleService.getRoleById(user.data.role_id).subscribe(
                (res: any) => {
                    let permissions:any[] = [];
                    if (res.data) {
                        if (res.data.role) {
                            if (res.data.role.permissions && res.data.role.permissions.length>0) {

                                res.data.role.permissions.forEach((x:any) => {
                                    permissions.push(x.name);
                                });

                                // console.log(res.data.role.permissions.length);
                                console.log(res.data.role.permissions);
                            }
                        }
                    }
                    localStorage.setItem('permissions_role', JSON.stringify(permissions));
                    window.location.href = '/dashboard';
                }
            );       
        }));
    }

    isLoggedIn() {
        return !!this.customLocalStorage.getItem('admin_id_token');
    }

    isHasPermission(activity:string, module:string) {
        let permission = `${activity} ${module}`;
        if (localStorage.getItem('permissions_role')) {
            // @ts-ignore
            let permissions: any[] = JSON.parse(localStorage.getItem('permissions_role'));
            // console.log(permission);
            return permissions.includes(permission);
        }
        return false;
    }

    logOutRevoke(){
        return this.http.get(`backoffice/token/revoke`);
    }
}

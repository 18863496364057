import { LetterCase } from "../letter-case/letter.case";
import { Injectable } from "@angular/core";
import { Arr } from "../arr/arr";
import { stateRef } from "../general-code/general.code";

@Injectable
({
    providedIn: 'root'
})

export class Obj {

    stateRef = stateRef;
    excludedField: string[] = [
        'id',
        'created_at',
        'updated_at',
        'deleted_at'
    ];

    constructor(
        private arr: Arr,
        private letterCase:LetterCase
    ) {}

    /**
     * @param str ==> string data object
     */
    spreadOut(str: any) {
        let datas:any[] = [];
        try {
            let newObj = JSON.parse(str);
            let keys = Object.keys(newObj);
            if (!['number', 'string'].includes(typeof newObj)) {
                keys.forEach((x: any) => {
                    let newObject = typeof newObj[x] === 'object' ? JSON.stringify(newObj[x]) : newObj[x];
                    if (!this.excludedField.includes(x)) {
                        datas.push({
                            field_name: this.letterCase.capitalizeEachWord(x, '_', ' '),
                            isObject: true,
                            value: newObject
                        });
                    }
                });
                return datas;
            }
            return datas = [
                {isObject: false, value: str}
            ];
        } catch (e:any) {
            if (!this.arr.unset.includes(str)) {
                return datas = [
                    {isObject: false, value: str}
                ];
            }
            return [];
        }
    }

    /**
     * @param action ==> ['create', 'delete', 'update', 'update-status']
     * @param strOld ==> string data object old
     * @param strNew ==> string data object new
     * @param column ==> column location ['new', 'old']
     */
    diffSpreadOut(action:string, strOld?:string, strNew?:string, column?:string) {
        if([this.stateRef.create, this.stateRef.delete].includes(action)) {
            if (this.arr.unset.includes(strOld) && column==='old') {
                return;
            } else if (this.arr.unset.includes(strNew) && column==='new') {
                return;
            }
        } else if ([this.stateRef.update, this.stateRef.updateStatus].includes(action)) {

        }
    }
}
<!-- Page Sidebar Start-->
<div class="sidebar-wrapper">
    <div id="sidebarEffect"></div>
    <div>
        <div class="logo-wrapper logo-wrapper-center">
            <a href="/" data-bs-original-title="" title="">
                <img class="img-fluid for-white" src="../../../assets/images/logo.png" alt="logo">
            </a>
            <div class="back-btn">
                <i class="fa fa-angle-left"></i>
            </div>
            <div class="toggle-sidebar">
                <i class="ri-apps-line status_toggle middle sidebar-toggle"></i>
            </div>
        </div>
        <div class="logo-icon-wrapper">
            <a href="/">
                <img class="img-fluid main-logo main-white" src="assets/images/logo/logo.png" alt="logo">
                <img class="img-fluid main-logo main-dark" src="assets/images/logo/logo-white.png" alt="logo">
            </a>
        </div>
        <nav class="sidebar-main">
            <div class="left-arrow" id="left-arrow">
                <i data-feather="arrow-left"></i>
            </div>

            <div id="sidebar-menu">
                <ul class="sidebar-links" id="simple-bar">
                    <li class="back-btn"></li>

                    <li class="sidebar-list">
                        <a class="sidebar-link sidebar-title link-nav" (click)="app.getMenu('dashboard')"
                            [ngClass]="{'active' : menu === 'dashboard'}" href="/dashboard" routerLinkActive="active">
                            <i class="ri-home-line"></i>
                            <span>Dashboard</span>
                        </a>
                    </li>

                    <li class="sidebar-list"
                        *ngIf="auth.isHasPermission('view', 'pending_approval') || auth.isHasPermission('view', 'log_approval')"
                        (click)="app.getMenu('approval')">
                        <a class="linear-icon-link sidebar-link sidebar-title"
                            [ngClass]="{'active' : menu === 'approval'}">
                            <i class="ri-folder-lock-line"></i>
                            <span>Approval Management </span><span class="badge">{{data}}</span>
                        </a>
                        <ul class="sidebar-submenu"
                            [ngStyle]="menu === 'approval' ? {display : 'block'} : {display : 'none'}">
                            <li>
                                <a [routerLink]="['/approval/list']" routerLinkActive="active"
                                    *ngIf="auth.isHasPermission('view', 'pending_approval')">Approval List</a>
                            </li>
                            <li>
                                <a [routerLink]="['/approval/logs']" routerLinkActive="active"
                                    *ngIf="auth.isHasPermission('view', 'log_approval')">Approval History</a>
                            </li>
                        </ul>
                    </li>

                    <li class="sidebar-list" (click)="app.getMenu('branch_management')">
                        <a class="linear-icon-link sidebar-link sidebar-title">
                            <i class="ri-home-3-line"></i>
                            <span>Branch Management</span>
                        </a>
                        <ul class="sidebar-submenu"
                            [ngStyle]="menu === 'branch_management' ? {display : 'block !important'} : {display : 'none'}">
                            <li>
                                <a [routerLink]="['/branch-management/branch/list']" routerLinkActive="active">Branch
                                    List</a>
                            </li>
                            <li>
                                <a [routerLink]="['/branch-management/branch/log']" routerLinkActive="active">Branch
                                    Upload</a>
                            </li>
                        </ul>
                    </li>

                    <li class="sidebar-list" *ngIf="auth.isHasPermission('view', 'customer')"
                        (click)="app.getMenu('customer')">
                        <a class="linear-icon-link sidebar-link sidebar-title">
                            <i class="ri-article-line"></i>
                            <span>Customer Management</span>
                        </a>
                        <ul class="sidebar-submenu"
                            [ngStyle]="menu === 'customer' ? {display : 'block !important'} : {display : 'none'}">
                            <li>
                                <a [routerLink]="['/customer/all']" routerLinkActive="active">Customer List</a>
                            </li>
                            <!-- <li>
                                            <a [routerLink]="['/customer/processing-log']" routerLinkActive="active">Customer Processing Log</a>
                                        </li>
                                        <li>
                                            <a [routerLink]="['/customer/search']" routerLinkActive="active">Search Customer</a>
                                        </li>
                                        <li>
                                            <a [routerLink]="['/customer/redemption-list']" routerLinkActive="active">Redemption List</a>
                                        </li>
                                        <li>
                                            <a [routerLink]="['/customer/wallet-transfer']" routerLinkActive="active">Wallet Transfer</a>
                                        </li> -->
                        </ul>
                    </li>

                    <li class="sidebar-list" *ngIf="auth.isHasPermission('view', 'earning_rule')"
                        (click)="app.getMenu('earning_rule')">
                        <a class="linear-icon-link sidebar-link sidebar-title">
                            <i class="ri-exchange-dollar-line"></i>
                            <span>Earning Management </span>
                        </a>
                        <ul class="sidebar-submenu"
                            [ngStyle]="menu === 'earning_rule' ? { display: 'block !important' } : { display: 'none' }">
                            <li>
                                <a [routerLink]="['earning-rules/all']" routerLinkActive="active">Earning Rules</a>
                            </li>
                        </ul>
                    </li>



                    <li class="sidebar-list" (click)="app.getMenu('campaign_management')">
                        <a class="linear-icon-link sidebar-link sidebar-title">
                            <i class="ri-speaker-3-line"></i>
                            <span>Campaign Management</span>
                        </a>
                        <ul class="sidebar-submenu"
                            [ngStyle]="menu === 'campaign_management' ? {display : 'block !important'} : {display : 'none'}">
                            <li>
                                <a [routerLink]="['campaign-management/list']" routerLinkActive="active">Campaign
                                    List</a>
                            </li>
                            <li>
                                <a [routerLink]="['campaign-management/gamification/list']"
                                    routerLinkActive="active">Gamification List</a>
                            </li>
                        </ul>
                    </li>

                    <li class="sidebar-list" *ngIf="auth.isHasPermission('view', 'wallet')"
                        (click)="app.getMenu('pocket')">
                        <a class="linear-icon-link sidebar-link sidebar-title">
                            <i class="ri-wallet-3-line"></i>
                            <span>Pocket Management</span>
                        </a>
                        <ul class="sidebar-submenu"
                            [ngStyle]="menu === 'pocket' ? {display : 'block !important'} : {display : 'none'}">
                            <li>
                                <a [routerLink]="['/pocket-management/pocket/list']" routerLinkActive="active">Pocket
                                    List</a>
                            </li>
                        </ul>
                    </li>

                    <li class="sidebar-list" *ngIf="auth.isHasPermission('view', 'level')"
                        (click)="app.getMenu('level')">
                        <a class="linear-icon-link sidebar-link sidebar-title">
                            <i class="ri-bar-chart-2-line"></i>
                            <span>Level Management</span>
                        </a>
                        <ul class="sidebar-submenu"
                            [ngStyle]="menu === 'level' ? {display : 'block !important'} : {display : 'none'}">
                            <li>
                                <a [routerLink]="['/level-management/level/list']" routerLinkActive="active">Level
                                    List</a>
                            </li>
                        </ul>
                    </li>
                    <li class="sidebar-list" *ngIf="
                        auth.isHasPermission('view', 'product_account_group') 
                        || auth.isHasPermission('view', 'product_account')" (click)="app.getMenu('channel')">
                        <a class="linear-icon-link sidebar-link sidebar-title">
                            <i class="ri-list-check-2"></i>
                            <span>Channel Management</span>
                        </a>
                        <ul class="sidebar-submenu"
                            [ngStyle]="menu === 'level' ? {display : 'block !important'} : {display : 'none'}">
                            <li *ngIf="auth.isHasPermission('view', 'product_account_group')">
                                <a [routerLink]="['/channel-management/channel/list']" routerLinkActive="active">All
                                    Channel
                                </a>
                            </li>
                            <li *ngIf="auth.isHasPermission('view', 'product_account')">
                                <a [routerLink]="['/channel-management/product/list']" routerLinkActive="active">All
                                    Product</a>
                            </li>
                            <li *ngIf="auth.isHasPermission('view', 'subproduct')">
                                <a [routerLink]="['/channel-management/sub-product/list']" routerLinkActive="active">All
                                    Sub
                                    Product</a>
                            </li>
                            <li *ngIf="auth.isHasPermission('view', 'card')">
                                <a [routerLink]="['/channel-management/card/list']" routerLinkActive="active">All
                                    Card</a>
                            </li>
                            <li *ngIf="auth.isHasPermission('view', 'subcard')">
                                <a [routerLink]="['/channel-management/sub-card/list']" routerLinkActive="active">All
                                    Sub
                                    Card</a>
                            </li>
                        </ul>
                    </li>
                    <li class="sidebar-list" (click)="app.getMenu('input_files')"
                        *ngIf="auth.isHasPermission('view', 'manual_redemption_process') || auth.isHasPermission('view', 'bonus_process')">
                        <a class="linear-icon-link sidebar-link sidebar-title">
                            <i class="ri-add-box-line"></i>
                            <span>Feeding Management</span>
                        </a>
                        <ul class="sidebar-submenu"
                            [ngStyle]="menu === 'input_files' ? {display : 'block !important'} : {display : 'none'}">
                            <li>
                                <a [routerLink]="['/input-files/bonus-files/list']" routerLinkActive="active">Manual
                                    Feeding</a>
                            </li>
                            <li>
                                <a [routerLink]="['input-files/manual-redeem/list']" routerLinkActive="active">Manual
                                    Redemption</a>
                            </li>
                        </ul>
                    </li>

                    <!-- <li class="sidebar-list" *ngIf="auth.isHasPermission('view', 'transaction')" (click)="app.getMenu('transaction')" >
                        <a class="linear-icon-link sidebar-link sidebar-title">
                            <i class="ri-contacts-book-upload-line"></i>
                            <span>Transaction</span>
                        </a>
                        <ul class="sidebar-submenu" [ngStyle]="menu === 'transaction' ? {display : 'block !important'} : {display : 'none'}">
                            <li>
                                <a [routerLink]="['transaction/list']" routerLinkActive="active">Transaction List</a>
                            </li>
                            <li>
                                <a [routerLink]="['transaction/processing-log']" routerLinkActive="active">Transaction
                                    Processing Log</a>
                            </li>
                        </ul>
                    </li> -->

                    <li class="sidebar-list">
                        <a class="linear-icon-link sidebar-link sidebar-title">
                            <i class="ri-gift-line"></i>
                            <span>Catalog Management</span>
                        </a>
                        <ul class="sidebar-submenu"
                            [ngStyle]="menu === 'reward' ? {display : 'block !important'} : {display : 'none'}">
                            <li>
                                <a [routerLink]="['reward-management/owned']" routerLinkActive="active">Catalog
                                    List</a>
                            </li>
                            <li>
                                <a [routerLink]="['reward-management/product-type']" routerLinkActive="active">Catalog
                                    Type</a>
                            </li>
                            <li>
                                <a [routerLink]="['reward-management/brand']" routerLinkActive="active">Catalog
                                    Brand</a>
                            </li>
                            <li>
                                <a [routerLink]="['reward-management/category']" routerLinkActive="active">Catalog
                                    Category</a>
                            </li>
                        </ul>
                    </li>

                    <li class="sidebar-list" (click)="app.getMenu('report')" *ngIf="auth.isHasPermission('view', 'report_settlement') 
                                || auth.isHasPermission('view', 'report_earning') 
                                || auth.isHasPermission('view', 'report_expired') 
                                || auth.isHasPermission('view', 'report_gl')">
                        <a class="linear-icon-link sidebar-link sidebar-title" href="javascript:void(0)">
                            <i class="ri-file-chart-line"></i>
                            <span>Report Management</span>
                        </a>
                        <ul class="sidebar-submenu"
                            [ngStyle]="menu === 'report' ? {display : 'block !important'} : {display : 'none'}">
                            <li *ngIf="auth.isHasPermission('view', 'report_gl')">
                                <a [routerLink]="['reports/gl-summary']" routerLinkActive="active">GL Report</a>
                            </li>
                            <!-- <li *ngIf="auth.isHasPermission('view', 'report_gl')">
                                <a [routerLink]="['reports/gl-bonus-summary']" routerLinkActive="active">GL Bonus Report</a>
                            </li> -->
                            <li *ngIf="auth.isHasPermission('view', 'report_settlement')">
                                <a [routerLink]="['reports/settlement']" routerLinkActive="active">Report
                                    Settlement</a>
                            </li>
                            <li *ngIf="auth.isHasPermission('view', 'report_earning')">
                                <a [routerLink]="['reports/earning']" routerLinkActive="active">Report
                                    Earning</a>
                            </li>
                            <li *ngIf="auth.isHasPermission('view', 'report_earning')">
                                <a [routerLink]="['reports/expiry']" routerLinkActive="active">Report Expiry</a>
                            </li>
                        </ul>
                    </li>

                    <li class="sidebar-list" *ngIf="auth.isHasPermission('view', 'email')"
                        (click)="app.getMenu('email')">
                        <a class="linear-icon-link sidebar-link sidebar-title">
                            <i class="ri-mail-line"></i>
                            <span>Email Management</span>
                        </a>
                        <ul class="sidebar-submenu"
                            [ngStyle]="menu === 'email' ? {display : 'block !important'} : {display : 'none'}">
                            <li *ngIf="auth.isHasPermission('view', 'email')">
                                <a [routerLink]="['/config/email/list']" routerLinkActive="active">Email
                                    List
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li class="sidebar-list" (click)="app.getMenu('config')"
                        *ngIf="auth.isHasPermission('view', 'config') || auth.isHasPermission('view', 'product_account') || auth.isHasPermission('view', 'product_account_group') || auth.isHasPermission('view', 'email') ">
                        <a class="linear-icon-link sidebar-link sidebar-title">
                            <i class="ri-settings-5-line"></i>
                            <span>Configuration</span>
                        </a>
                        <ul class="sidebar-submenu"
                            [ngStyle]="menu === 'config' ? {display : 'block !important'} : {display : 'none'}">
                            <li *ngIf="auth.isHasPermission('view', 'config')">
                                <a [routerLink]="['/config/config-global/list']" routerLinkActive="active"
                                    *ngIf="auth.isHasPermission('view', 'config')">Global
                                    Settings
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li class="sidebar-list" (click)="app.getMenu('content')">
                        <a class="linear-icon-link sidebar-link sidebar-title">
                            <i class="ri-layout-line"></i>
                            <span>Content Management</span>
                        </a>
                        <ul class="sidebar-submenu"
                            [ngStyle]="menu === 'content' ? {display : 'block !important'} : {display : 'none'}">
                            <li>
                                <a [routerLink]="['/content-management/banner/list']" routerLinkActive="active">
                                    Banner List
                                </a>
                            </li>
                            <!-- <li>
                                <a routerLinkActive="active">
                                    Content List
                                </a>
                            </li> -->
                        </ul>
                    </li>

                    <!-- <li class="sidebar-list" (click)="app.getMenu('redemption')" >
                        <a class="linear-icon-link sidebar-link sidebar-title">
                            <i class="ri-exchange-line"></i>
                            <span>Redemption Management</span>
                        </a>
                        <ul class="sidebar-submenu" [ngStyle]="menu === 'redemption' ? {display : 'block !important'} : {display : 'none'}">
                            <li>
                                <a routerLinkActive="active">
                                    Redemption Rules
                                </a>
                            </li>
                        </ul>
                    </li> -->

                    <li class="sidebar-list" (click)="app.getMenu('audit_trail')">
                        <a class="linear-icon-link sidebar-link sidebar-title">
                            <i class="ri-record-mail-line"></i>
                            <span>Audit Trail Report</span>
                        </a>
                        <ul class="sidebar-submenu"
                            [ngStyle]="menu === 'audit_trail' ? {display : 'block !important'} : {display : 'none'}">
                            <li>
                                <a [routerLink]="['/audit-trail-report/log-activity']" routerLinkActive="active">
                                    Log Activity
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>

            <div class="right-arrow" id="right-arrow">
                <i data-feather="arrow-right"></i>
            </div>
        </nav>
    </div>
</div>
<!-- Page Sidebar Ends-->